import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { Helpers } from './mixins'
import vuetify from './plugins/vuetify';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import './assets/css/main.css'

const firebaseConfig = {
  apiKey: "AIzaSyAW1WfrKtr31fGp-bRUZBuCKrNhkoqXhe4",
  authDomain: "axsky-3d364.firebaseapp.com",
  projectId: "axsky-3d364",
  storageBucket: "axsky-3d364.appspot.com",
  messagingSenderId: "927352667368",
  appId: "1:927352667368:web:7acd2676fa099bc9f49d5d",
  measurementId: "G-8D6T4F1ZK4"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

Vue.config.productionTip = false

Vue.mixin( Helpers )
Vue.use(analytics)

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
