import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/', name: 'home', component: () => import('./views/Home.vue') },
    { path: '/about', name: 'about', component: () => import('./views/About.vue') },
    { path: '/work', name: 'work', component: () => import('./views/Work.vue') },
    { path: '/software', name: 'software', component: () => import('./views/Software.vue') },
    { path: '/deploy', name: 'deploy', component: () => import('./views/Deploy.vue') },
    { path: '/execute', name: 'execute', component: () => import('./views/Execute.vue') },
    { path: '/knowledge', name: 'knowledge', component: () => import('./views/Knowledge.vue') },
    { path: '/contact', name: 'contact', component: () => import('./views/Contact.vue') },
    { path: '/sky', beforeEnter() { window.location.replace("https://sky.alphaxds.com") } },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
