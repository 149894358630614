export const Helpers = {
  computed: {
    xHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return ''
        case 'sm': return ''
        case 'md': return 'half-height'
        case 'lg': return 'half-height'
        case 'xl': return 'full-height'
        default: return ''
      }
    }
  }
}